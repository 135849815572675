app.factory('gravDesigner', function($rootScope) {

    /*************************************
    We pre-define some anonymos funcions
    that will only exist/operate inside the
    sketcher object.
    *************************************/
    var sketcherFontChange = function() {},
        sketcherBgChange = function() {},
        insertSymbol = function() {},
        sketcherFontSize = function() {},
        loadFonts = function() {},
        bindAngularVars = function() {}

    return {
        sketcher: function(sketch) {

            /*************************************
            	Variables
            *************************************/
            var myCanvas, myDiv;
            var angularVars     = {}
            var divSelector     = dansteinDesigner.canvasContainer;
            var fontDir         = dansteinDesigner.wordpress.themedir + 'library/fonts/';
            var stone           = {
                w: dansteinDesigner.defaults.stone.w,
                h: dansteinDesigner.defaults.stone.h,
                tlbX: dansteinDesigner.defaults.stone.tlbX,
                tlbY: dansteinDesigner.defaults.stone.tlbY,
                brbX: dansteinDesigner.defaults.stone.brbX,
                brbY: dansteinDesigner.defaults.stone.brbY,
                img: dansteinDesigner.defaults.stone.img
            }
            var background = {
                default: dansteinDesigner.defaults.images.bg,
                img: null
            }
            var txtTest = [];
            sketch.stopPropagation = false;
            /*************************************

            	preload()
                runs once, loads all default content into canvas

            *************************************/
            sketch.preload = function() {
            	myDiv          = sketch.select('#' + divSelector);
            	stone.img      = sketch.loadImage(stone.img);
            	background.img = sketch.loadImage(dansteinDesigner.defaults.images.bg);
                loadFonts();
            }
            /*************************************

            	setup()
                waits until preload() is done

            *************************************/
            sketch.setup = function() {
            	var newCanvas = ensureRation(4 / 3, myDiv);
            	myCanvas = sketch.createCanvas(newCanvas.width, newCanvas.height);
            	myCanvas.parent(divSelector);
            	sketch.frameRate(30);
                if ($rootScope.customImage) {
                    myCanvas.background('rgb(243,243,243)');
                    var dims = getCustomImageDimensions()
                    sketch.imageMode(sketch.CENTER)
                    sketch.image(background.img, newCanvas.width/2, newCanvas.height/2, dims.width, dims.height);
                } else {
                    myCanvas.background(background.img);
                    drawStone(stone);
                }

                if (angularVars.texts) {
                    // begin by defining the 3 text fields.
                    txtTest.push(new Text(angularVars.texts.name, sketch, angularVars,0))
                    txtTest.push(new Text(angularVars.texts.date, sketch, angularVars,1, true))
                    txtTest.push(new Text(angularVars.texts.last, sketch, angularVars,2))
                    txtTest.push(new Text(angularVars.texts.eks1, sketch, angularVars,3))
                    txtTest.push(new Text(angularVars.texts.eks2, sketch, angularVars,4))
                    txtTest.push(new Text(angularVars.texts.eks3, sketch, angularVars,5))
                    txtTest.push(new Text(angularVars.texts.eks4, sketch, angularVars,6))
                    txtTest.push(new Text(angularVars.texts.eks5, sketch, angularVars,7))
                    txtTest.push(new Text(angularVars.texts.eks6, sketch, angularVars,8))
                }
            }
            /*************************************

            	draw()
                Renders the sketch.
                Here you can also alter the order:
                    1. background
                    2. stone
                    3. text lines
                    4. decoration

            *************************************/
            sketch.draw = function() {
            	sketch.cursor(sketch.ARROW)
                if ($rootScope.customImage) {
                    myCanvas.background('rgb(243,243,243)');
                    var dims = getCustomImageDimensions()
                    sketch.imageMode(sketch.CENTER)
                    sketch.image(background.img, myCanvas.width/2, myCanvas.height/2, dims.width, dims.height);
                } else {
                    myCanvas.background(background.img);
                    drawStone(stone);
                }
                getTextInput();
                
                // Make text uppercase if caps is true
                if (angularVars.font.caps) {
                    angularVars.texts.name.content = angularVars.texts.name.content.toUpperCase()
                    angularVars.texts.date.content = angularVars.texts.date.content.toUpperCase()
                    angularVars.texts.last.content = angularVars.texts.last.content.toUpperCase()
                    angularVars.texts.eks1.content = angularVars.texts.eks1.content.toUpperCase()
                    angularVars.texts.eks2.content = angularVars.texts.eks2.content.toUpperCase()
                    angularVars.texts.eks3.content = angularVars.texts.eks3.content.toUpperCase()
                    angularVars.texts.eks4.content = angularVars.texts.eks4.content.toUpperCase()
                    angularVars.texts.eks5.content = angularVars.texts.eks5.content.toUpperCase()
                    angularVars.texts.eks6.content = angularVars.texts.eks6.content.toUpperCase()
                }

                for (var i = 0; i < txtTest.length; i++) {
                    if (txtTest) {
                        txtTest[i].display(i)
                    }
                }
                // drawText(newText.txt1, newText.txt2, newText.txt3) // old function
                if (angularVars.symbols) {
                  for (var i = 0; i < angularVars.symbols.length; i++) {
                    angularVars.symbols[i].display(i)
                  }
                } else {
                  console.log(angularVars)
                }
            }
            /*************************************

            	mousePressed()

            *************************************/
            sketch.mousePressed = function() {
                // Add all symbols on the canvs together into one list including text
                var allCanvasSymbols = angularVars.symbols.concat(txtTest)

                for (var i = 0; i < allCanvasSymbols.length; i++) {
                    if (sketch.stopPropagation || allCanvasSymbols[i].startDrag()) {
                        break
                    }
                }
                sketch.stopPropagation = false;
            }
            /*************************************

            	mouseReleased()

            *************************************/
            sketch.mouseReleased = function() {
                for (var i = 0; i < txtTest.length; i++) {
                    txtTest[i].stopDrag()
                }
                for (var i = 0; i < angularVars.symbols.length; i++) {
                    angularVars.symbols[i].stopDrag()
                }
            }
            /*************************************

                CANVAS RENDER FUNCTIONS

            *************************************/
            sketcherFontSize = function(input, id) {
                if(typeof input === typeof 1) {
                    if (!(txtTest.length < id) && id > 0) {
                      txtTest[id-1].changeFontSize(input)
                      try {
                        sketch.redraw();
                      } catch (err) {
                          // console.error();
                      }
                    }
                }
            }
            bindAngularVars = function(input) {
                /*
                    We bind the variables fetched from the
                    angular side, to a GLOBAL variable inside
                    the sketcher object. We also pass the current
                    sketch, so we don't redefine a new sketch instance.

                    This will ensure that all updates are consistent
                    inside/outside of the sketch.
                */
                angularVars = input;

                if (angularVars.symbols) {
                  for (var i = 0; i < angularVars.symbols.length; i++) {
                      angularVars.symbols[i].updateSketch(sketch)
                  }
                } else {
                  console.log(angularVars)
                }

                try {
                  sketch.redraw();
                } catch (err) {
                    //console.error('Failed to redraw background graphics',err)
                }
            }
            loadFonts = function() {
                for (var i = 0; i < FONTS.length; i++) {
                    var font = FONTS[i];
                    if (font.backup) {
                        continue;
                    }
                    var fontFile = font.main + '.';
                    if (font.encoding) {
                        fontFile += font.encoding;
                    } else {
                        fontFile += 'ttf';
                    }
                    sketch.loadFont(fontDir + fontFile);
                }
            }
            sketcherBgChange = function(input) {
                if (!$rootScope.customImage) {
                    stone.img = sketch.loadImage(input.stone); // Fetch the stone from step 2
                }
                background.img  = sketch.loadImage(input.bg); // Fetch the type background from step 1 (or 2)
                try {
                  sketch.redraw();
                } catch (err) {
                    console.error('Failed to redraw background graphics',err)
                }
            }
            sketcherFontChange = function(input) {
                if(typeof(input) == typeof([])) {

                    angularVars.font = input; // Change the font variables
                    if (angularVars.symbols) {
                      angularVars.symbols.forEach(function (symbol) {
                          symbol.reloadImage() // reload all active angularVars.symbols
                      })
                    }
                    try {
                      sketch.redraw(); // redraw the sketch
                    } catch (err) {
                        //console.error('Failed to change font',err)
                        // this errors still shows?
                    }
                }
            }
            function drawStone(stone) {
              setProperAspectRatio(stone) // We recalculate a proper height and width for the stone image
            	sketch.stroke(255);
            	sketch.noFill();
            	sketch.imageMode(sketch.CENTER)
            	sketch.image(stone.img, sketch.width / 2, sketch.height / 2, stone.w, stone.h)
            	sketch.imageMode(sketch.CORNER)
            	sketch.rectMode(sketch.CORNERS)

            	var tlbX = sketch.width / 2 - stone.tlbX
            	var tlbY = sketch.height / 2 - stone.tlbY
            	var brbX = sketch.width / 2 + stone.brbX
            	var brbY = sketch.height / 2 + stone.brbY

            	// outerBounds = {
            	// 	tlbX: tlbX,
            	// 	tlbY: tlbY,
            	// 	brbX: brbX,
            	// 	brbY: brbY
            	// }
            }
            function getStyle() {
              if (angularVars.font && angularVars.font.style) {
                switch (angularVars.font.style) {
                  case 'italic':
                    return sketch.ITALIC;
                    break;
                  case 'bold':
                    return sketch.BOLD;
                    break;
                  default:
                    return sketch.NORMAL;
                }
              } else {
                return sketch.NORMAL;
              }
            }
            function getTextInput() {
                // Update global variables.
                if (angularVars.texts) {
                  angularVars.texts.name.content = sketch.select('#line1').elt.value;
                  angularVars.texts.last.content = sketch.select('#line3').elt.value;
                  angularVars.texts.eks1.content = sketch.select('#line4').elt.value;
                  angularVars.texts.eks2.content = sketch.select('#line5').elt.value;
                  angularVars.texts.eks3.content = sketch.select('#line6').elt.value;
                  angularVars.texts.eks4.content = sketch.select('#line7').elt.value;
                  angularVars.texts.eks5.content = sketch.select('#line8').elt.value;
                  angularVars.texts.eks6.content = sketch.select('#line9').elt.value;

                      var birthdate = sketch.select('#birth').elt.value;
                      var deathdate = sketch.select('#death').elt.value;

                      var birth_death_checkbox = sketch.select('#birth_death_label').elt.checked; // boolean

                      var seperator = '',
                          star = '',
                          cross = '';

                      if(birthdate && deathdate && !birth_death_checkbox) {
                          seperator = ' - ';
                      }
                      if(birth_death_checkbox) {
                          star = '* ';
                          cross = ' + ';
                      }

                  angularVars.texts.date.content = star + birthdate + seperator + cross + deathdate;
                }
            }
            function getCustomImageDimensions() {
                var img = background.img
                var maxWidth = myCanvas.width, maxHeight = myCanvas.height
                var dims = {
                    width: myCanvas.width,
                    height: myCanvas.height
                }

                // resizing the width to fit and keep ratio
                var ratio = img.height / img.width
                dims.width = maxWidth
                dims.height = dims.width * ratio
                // if the new height is bigger than max, resize again
                if (dims.height > maxHeight) {
                    var ratio = img.width / img.height
                    dims.height = maxHeight
                    dims.width = dims.height * ratio
                }

                return dims
            }
            insertSymbol = function(url,decor) {
                var s = new _Symbol(sketch.width / 2, sketch.height / 2, url, sketch,decor, angularVars)
                jQuery(document).on( "mouseup", function() {
                    s.stopDrag()
                })
                angularVars.symbols.push(s)
            }
            /*      end of sketcher         */
        },
        windowResized: function () {
         	myDiv = sketch.select('#' + divSelector);
         	var newCanvas = sketch.ensureRation(4 / 3, myDiv);
         	sketch.resizeCanvas(newCanvas.width, newCanvas.height);
            try { sketch.redraw() } catch(err) {}
        },
        /*
            Fetch data from the controller
            and executes a local function
            inside the sketcher object
        */
        setFontFunction: function(input) {sketcherFontChange(input)},
        setFontSize: function(input, id) {sketcherFontSize(input, id)},
        setBgFunction: function(input) {sketcherBgChange(input)},
        insertSymbol: function(input,decor) {insertSymbol(input,decor)},
        insertText: function() {insertText()},
        bindVars: function(input) {bindAngularVars(input)},
      }
});
