/*
    Here we make all the calls to fetch data from the REST API.
*/
app.factory('dataFactory', ['$http', function($http) {

    /*
        Variables
    */
    var urlBase         = '/wp-json/wp/v2';
    var dataFactory     = {};
    var query           = '?per_page=100';

    dataFactory.fetchPage = function (id) {
        return $http.get(urlBase + '/pages/' + id);
    };

    dataFactory.fetchData = function(postType, perPage, page) {
        return $http.get(urlBase + '/' + postType + '?per_page=' + perPage + '&page=' + page)
    }

    dataFactory.fetchProducts = function (page, perpage) {
        return $http.get(urlBase + '/products?per_page=' + perpage + '&page=' + page);
    };
    dataFactory.fetchStoneTypes = function () {
        return $http.get(urlBase + '/stentype' + query);
    };
    dataFactory.fetchGraveTypes = function () {
        return $http.get(urlBase + '/gravtype');
    };
    dataFactory.fetchDecors = function (page = 1, perpage = 100) {
        return $http.get(urlBase + '/decor?per_page=' + perpage + '&page=' + page);
    };
    dataFactory.fetchDecorCats = function () {
        return $http.get(urlBase + '/decor_cat' + query);
    };
    dataFactory.sendPost = function(metadata) {
        console.log('Sending request at sendPost')
        return $http.post(dansteinDesigner.wordpress.ajaxurl, metadata);
    };
    dataFactory.getAmount = function(postType) {
        return $http.get(urlBase + '/' + postType + '?per_page=1');
    }

    function sendData(res, page, callBack) {
      callBack(res);
    }

    dataFactory.fetchAllProducts = function (page, callBack) {
        var produktData     = {data: []}
        for (let i = 1; i <= page; i++) {
          dataFactory.fetchProducts(i,100)
            .then(function (response) {
                produktData.data = produktData.data.concat(response.data)
                sendData(produktData, page, callBack)
            });
        }
    };

    dataFactory.fetchAllDecors = function (page, callBack) {
        var produktData = {data: []}
        for (let i = 1; i <= page; i++) {
          dataFactory.fetchDecors(i)
            .then(function (response) {
                produktData.data = produktData.data.concat(response.data)
                sendData(produktData, page, callBack)
            });
        }
    };

    return dataFactory;

}]);
