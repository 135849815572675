/*

    Variables &
    Support Functions

    - dansteinDesigner is a variable, that gets values from the CMS system.
*/
Math.radians = function(degrees) {
  return degrees * Math.PI / 180;
};
/**
 * Calculates coordinates that are
 * used to check if mouse is inside
 * of a given element.
 * @param  {int} h height
 * @param  {int} w width
 * @param  {int} x x-coordinate
 * @param  {int} y y-coordinate
 * @return {object}   {A,B,C,D}
 */
function getCoords(h,w,x,y) {
    var x1 = x - w/2
    var x2 = x + w/2
    var y1 = y - h/2
    var y2 = y + h/2
    return {
        A: {x: x2, y: y2},
        B: {x: x1, y: y2},
        C: {x: x1, y: y1},
        D: {x: x2, y: y1},
    }
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function compare(a,b) {
  if (a.id < b.id)
    return -1;
  if (a.id > b.id)
    return 1;
  return 0;
}

function ensureRation (rat, div) {

    var ratio = rat;
    var w = div.width;
    var h = div.height;

    if (h == 0) {
        var newHeight = w / ratio;
        h = newHeight;
    } else if (w > ratio * h) {
        w = (ratio * h);
    } else if (h > w / ratio) {
        var newHeight = w / ratio;
        h = newHeight;
    }

    return { width: w, height: h };
}

function mPointInRectangle(m, r) {
    var AB = mVector(r.A, r.B);
    var AM = mVector(r.A, m);
    var BC = mVector(r.B, r.C);
    var BM = mVector(r.B, m);
    var dotABAM = mDot(AB, AM);
    var dotABAB = mDot(AB, AB);
    var dotBCBM = mDot(BC, BM);
    var dotBCBC = mDot(BC, BC);
    return 0 <= dotABAM && dotABAM <= dotABAB && 0 <= dotBCBM && dotBCBM <= dotBCBC;
}

function mVector(p1, p2) {
    return {
            x: (p2.x - p1.x),
            y: (p2.y - p1.y)
    };
}

function mDot(u, v) {
    return u.x * v.x + u.y * v.y;
}

var FONTS = [
    {main: 'ANTIKVA', indhugget: true, caps: true }, //This font only supports capital letters
    {main: 'Amalienborg', indhugget: false },
    {main: 'Libre-Baskerville', indhugget: true, encoding: 'otf' },
    {main: 'Blok-Petit', backup: 'Helvetica', indhugget: true},
    {main: 'BLOK', backup: 'Helvetica', indhugget: true, caps: true},
    {main: 'Blok-Petit-Kursiv', backup: 'Helvetica', indhugget: true, fontStyle: 'italic'},
    {main: 'Bookman-Old-Style', indhugget: true },
    {main: 'Brush-Script', indhugget: true, encoding: 'otf' },
    {main: 'Calisto-MT', indhugget: true },
    {main: 'Christiansborg', indhugget: false },
    {main: 'Times-New-Roman', backup: 'Times New Roman', indhugget: true },
    {main: 'Optima-Bold', indhugget: true },
    {main: 'Optima-Bold-Oblique', indhugget: true },
    {main: 'Vag-Rounded', indhugget: true },
    {main: 'Rosenborg', indhugget: false },
    {main: 'Slot', indhugget: false },
    {main: 'Storm1', indhugget: false },
    {main: 'Storm-2', indhugget: false, encoding: 'otf' },
    {main: 'Storm3', indhugget: false },
    {main: 'Gothic-Script', indhugget: true },
    {main: 'Kaligrafia', indhugget: true },
    {main: 'Monotype-Corsiva', indhugget: true },
    {main: 'Palatino-Linotype', indhugget: true },
    {main: 'Schwabacher', indhugget: true },
    {main: 'Comic-Sans', indhugget: true },
    {main: 'Volta', indhugget: true },
    {main: 'Formal-Script', indhugget: true },
    {main: 'Script-MT-Bold', indhugget: true },
    {main: 'Freestyle-Script-Bold', indhugget: true },
    {main: 'Kristen-ITC', indhugget: true },
    {main: 'Eras-Bold-ITC', indhugget: true },
    {main: 'Forte', indhugget: true },
    // {main: 'Old-English', indhugget: true },
    {main: 'Murray-Hill', indhugget: true },
    //'Harlow Solid Italic', //File crashes the system
    {main: 'Skriveskrift', indhugget: true },
    // {main: 'Omega-Bold', indhugget: true, encoding: 'TTF' },
    {main: 'ZapfChan-Bold', indhugget: true, fontStyle: 'bold' },
    {main: 'Baeumler', indhugget: false },
    {main: 'Bischoph', indhugget: false },
    {main: 'Bruno', indhugget: false },
    {main: 'Dostal', indhugget: false },
    {main: 'ErnstSempera', indhugget: false },
    {main: 'Finestra', indhugget: false },
    {main: 'GerhardBlock', indhugget: false },
    {main: 'Gruber', indhugget: false },
    {main: 'HelgaBlock', indhugget: false },
    {main: 'Jansen', indhugget: false },
    {main: 'Linea', indhugget: false },
    {main: 'Lorenz', indhugget: false },
    {main: 'Matheis', indhugget: false },
    {main: 'Neukamp', indhugget: false },
    {main: 'Norden', indhugget: false },
    {main: 'Pflueger', indhugget: false },
    {main: 'RuthSempera', indhugget: false },
    {main: 'Scala', indhugget: false },
    {main: 'Siehler', indhugget: false },
    {main: 'Karund', indhugget: false },
    {main: 'Traub', indhugget: false },
    {main: 'Walcher', indhugget: false },
    {main: 'Wiesner', indhugget: false },
    {main: 'Elegant', indhugget: false },
    {main: 'Hobo', indhugget: true, encoding: 'otf' },
];

var dansteinDesigner = {
    wordpress: WP_API_Settings,

    canvasContainer: 'myContainer',

    count: {
        product: Math.ceil(Number(WP_API_Settings.products.publish)/100),
        decor: Math.ceil(Number(WP_API_Settings.decors.publish)/100),
        stentype: Math.ceil(Number(WP_API_Settings.stentype)/100),
        decor_cat: Math.ceil(Number(WP_API_Settings.decor_cat)/100),
        gravtype: Math.ceil(Number(WP_API_Settings.gravtype)/100),
    },

    defaults: {
        reload: false, // false in dev mode.
        stone: {
            w: 650,     // Width or the max width of the stone
            h: 600,     // Height or the max height of the stone
            tlbX: 290,  // Top left bound X
            tlbY: 110,  // Top left bound Y
            brbX: 260,  // Bottom right bound X
            brbY: 225,   // Bottom right bound y
            img: WP_API_Settings.themedir + 'library/images/loading_sten.png',
        },
        images: {
            loading: WP_API_Settings.themedir + 'library/svg/logo.svg',
            bg: WP_API_Settings.themedir + 'library/images/grass.jpg',
            error: WP_API_Settings.themedir + 'library/images/loading_sten.png'
        },
        textLines: {
            lines: [
                {x: 0, y: 0, text: ''},
                {x: 0, y: 0, text: ''},
                {x: 0, y: 0, text: ''},
                {x: 0, y: 0, text: ''},
                {x: 0, y: 0, text: ''},
                {x: 0, y: 0, text: ''},
                {x: 0, y: 0, text: ''}
            ]
        },
        font: {
            family: 'Skriveskrift',
            style: 'normal',
            color: {
                name: 'Sort',
                hex: '#000000',
                rgb: {r: 0, g: 0, b: 0}
            },
            caps: false,
            indhugget: true,
            category: '5' // Sort
        },
        scale: {
            limit: true,        // should there be a limit?
            upper: 1.25,
            lower: 0.125,
            stepsize: 0.05
        }
    }
}

/**
 * This function takes a stone object and recalculates the proper width and height
 * of the image, so the aspect ratio is repected while keeping the stone within the
 * bounds of the canvas
 * @param {Object} stone The stone object, contains a p5.image and h, w
 */
function setProperAspectRatio(stone) {

  stone.h = dansteinDesigner.defaults.stone.h
  stone.w = dansteinDesigner.defaults.stone.w

  if (stone.img.height < stone.img.width) {
    // We calculate the proper height for the stone, so that we keep the aspect ratio intact
    stone.h = stone.w / stone.img.width * stone.img.height

    // Since the hight is larger that the max value
    if (stone.h > dansteinDesigner.defaults.stone.h) {
      // We set the height to the max value
      stone.h = dansteinDesigner.defaults.stone.h
      // And recalculate the width for a proper aspect ratio
      stone.w = stone.h / stone.img.height * stone.img.width
    }
  } else if(stone.img.height > stone.img.width) {
    // We calculate the proper width for the stone, so that we keep the aspect ratio intact
    stone.w = stone.h / stone.img.height * stone.img.width
    if (stone.w > dansteinDesigner.defaults.stone.w) {
      stone.w = dansteinDesigner.defaults.stone.w
      stone.h = stone.w / stone.img.width * stone.img.height
    }
  } else {
    // We chose the smallest of the max values to keep the stone from going overbounds
    if (dansteinDesigner.defaults.stone.h > dansteinDesigner.defaults.stone.w) {
      stone.w = dansteinDesigner.defaults.stone.w
      stone.h = dansteinDesigner.defaults.stone.w
    } else {
      stone.w = dansteinDesigner.defaults.stone.h
      stone.h = dansteinDesigner.defaults.stone.h
    }
  }
}

/*
    If it is set to true, the visitor will be prompted before reloading the page.
*/
if(dansteinDesigner.defaults.reload) {
    var pageTemplateBeregner = document.getElementsByTagName("body")[0].className.match(/page-template-page-beregner/);
    if (pageTemplateBeregner != null) {
        window.onbeforeunload = function() {
          return "Alt data som du har indtastet vil gå tabt. Er du sikker på du vil genindlæse siden?";
        };
    }
}

var divSelector     = dansteinDesigner.canvasContainer;
var svgDir          = dansteinDesigner.wordpress.themedir + 'library/svg/';
