/*
    This will build the text lines
    that we will need to draw the
    text on the sketch.
*/
function Text(textObject, p5sketch, variables, index, hideRotate) {

    /**
     * Ensure we don't create multiple sketches/canvas
     * @type {element} p5.js canvas
     */
    var sketch  = p5sketch;
    /**
     * Allows us to access self from all internal scopes
     * @type {object}
     */
    var self    = this;

    this.h          = textObject.height;
    this.w          = textObject.width;
    this.x          = textObject.position.x;
    this.y          = textObject.position.y;
    this.fontSize   = textObject.fontsize;
    this.isDragging = false;
    this.isRotating = false;
    this.mouseVec   = {x:0,y:0,r:0};
    this.rotation = 0
    this.hideRotate = hideRotate

    this.removeImg = null

    // Updates the current sketch reference
    this.updateSketch = function(newSketch) {
      sketch = newSketch
    }

    // Draws the text
    this.display = function(index) {
        if (!textObject.content || textObject.content == '') {
          return false
        }

        var textLine        = textObject.content;

        // This block of code takes care of hardcoding the star and cross typografi
        if (textObject.fancy) {
          sketch.textFont(variables.font.name);
          sketch.textSize(this.fontSize);
          var starIndex = textObject.content.indexOf('*')
          var crossIndex = textObject.content.indexOf('+')

          if (starIndex != -1 && crossIndex != -1) {
            var starObject = {
                position: {x: this.x-(this.w/2)+sketch.textWidth('*'), y: this.y},
                content: '*',
                height: this.h,
                fontsize: this.fontSize,
                width: 150,
                placeholder: '',
                zindex: 110,
                permaFont: 'Skriveskrift'
            }
            var crossObject = {
                position: {x: this.x-this.w/2+sketch.textWidth(textObject.content.slice(0, crossIndex+1)), y: this.y},
                content: '+',
                height: this.h,
                fontsize: this.fontSize,
                width: 150,
                placeholder: '',
                zindex: 110,
                permaFont: 'Skriveskrift'
            }

            var starText = new Text(starObject, p5sketch, variables, index + 0.1, true)
            starText.display(index+0.1)

            var crossText = new Text(crossObject, p5sketch, variables, index + 0.2, true)
            crossText.display(index+0.2)

            var spaceDist = sketch.textWidth(' ')
            sketch.textFont(crossObject.permaFont)
            var starDist = sketch.textWidth('*')
            var crossDist = sketch.textWidth('+')

            var starSpaces = Array(Math.ceil(starDist/spaceDist)).join(' ')
            var crossSpaces = Array(Math.ceil(crossDist/spaceDist)).join(' ')

            textLine = textObject.content.replace('*', starSpaces).replace('+', crossSpaces)
          }
        }

        sketch.push(); // Start a new drawing state
            sketch.imageMode(sketch.CENTER)
            sketch.rectMode(sketch.CENTER) // defines rectMode of the element
            sketch.textAlign(sketch.CENTER);
            sketch.textStyle(getStyle());
            sketch.textSize(this.fontSize);
            sketch.translate(this.x, this.y) // push the text location
            sketch.rotate(this.rotation);

            if (textObject.permaFont) {
              sketch.textFont(textObject.permaFont)
            } else {
              sketch.textFont(variables.font.name);
            }


            /*  If there's multiple lines,
                we set the textbox width to the widest of the lines.
            */
            var lines = textLine.split('\n');
            var largest = 0;
            for(var i = 0;i < lines.length;i++){
                var lineWidth = sketch.textWidth(lines[i]);
                if (lineWidth > largest) {
                    largest = lineWidth;
                }
            }
            textObject.width    = largest;

            if(textObject.width > 0) {

            const textBox     = {
                x : this.x,
                y : this.y,
                w : textObject.width + 20,
                h : this.h
            }

            if(variables.font.indhugget) {
                // set shadow font style
                sketch.fill(0,0,0,200);
                sketch.noStroke();
                sketch.text(textLine, -1, -1, textBox.w, textBox.h); // draw the first instance of text
                sketch.stroke(0, 0, 0, 200);
            } else {
                // set shadow font style
                sketch.fill(0,0,0,127.5);
                sketch.noStroke();
                sketch.text(textLine, 2, 2, textBox.w, textBox.h); // draw the first instance of text
            }
            // sketch.rect(textBox.x, textBox.y, textBox.w, textBox.h) // dev

            sketch.fill(variables.font.color.rgb.r, variables.font.color.rgb.g, variables.font.color.rgb.b, 255);

            sketch.text(textLine, 0, 0, textBox.w, textBox.h); // draw the first instance of text

            this.w  = textBox.w;
            this.h  = textBox.h;
            }

        sketch.pop(); // Restore original state

        var m   = {x: sketch.mouseX, y: sketch.mouseY}
        var r   = getCoords(this.h,this.w,this.x,this.y); // getCoords(h,w,x,y)
        var inTextBox = mPointInRectangle(m, r); // boolean

        if (this.isDragging) {
            sketch.cursor(sketch.MOVE)
            // this.x = sketch.mouseX
            // this.y = sketch.mouseY
            this.x = sketch.mouseX + this.mouseVec.x
            this.y = sketch.mouseY + this.mouseVec.y
            textObject.position.x = this.x
            textObject.position.y = this.y
        } else if (this.isRotating) {
            this.rotation = Math.atan2(sketch.mouseY-textObject.position.y, sketch.mouseX-textObject.position.x)-this.mouseVec.r; // todo use current this.rotation to prevent initial rotation on click
        }

        this.displayHover(index, inTextBox)
    }

    // Change font size
    this.changeFontSize = function(number) {

        this.fontSize += number;
        this.h += number;
        textObject.fontsize = this.fontSize;
        textObject.height = this.h;

    }

    // Drags the text across the canvas
    this.startDrag = function(index) {
        var m = {x: sketch.mouseX, y: sketch.mouseY}
        var r = getCoords(this.h,this.w,this.x,this.y);
        var inTextBox = mPointInRectangle(m, r);

        if (inTextBox) {

            if (!this.isDragging) {
              this.mouseVec.x = this.x - sketch.mouseX
              this.mouseVec.y = this.y - sketch.mouseY
            }

            this.x = sketch.mouseX + this.mouseVec.x
            this.y = sketch.mouseY + this.mouseVec.y
            this.isDragging = true

            return true
        }
        return false
    }

    // Stops the text from being dragged
    this.stopDrag = function () {
        this.isDragging = false;
        this.isRotating = false
    }

    // What to display when hovering over the text
    this.displayHover = function (i, inTextBox) {
        this.cleanHover()
        /*
         * What happens when you hover the text element/box
         */
        if (inTextBox && !this.isDragging && !this.isRotating) {
            sketch.cursor(sketch.MOVE)

            var imageSize = 20;
            if (!this.hideRotate) {
              this.rotateImg = sketch.createImg(svgDir + 'rotate.svg')
              this.rotateImg.parent(divSelector)
              this.rotateImg.addClass('rotateImg')
              this.rotateImg.size(imageSize, imageSize)
              // this.rotateImg.position(this.x - this.w/2+10, this.y - this.h/2+10)
              this.rotateImg.position(this.x + imageSize/2, this.y + 10)
              this.rotateImg.mousePressed(this.rotateImgPressed)
            }
        }
    }

    this.cleanHover = function() {
        if (this.rotateImg) { this.rotateImg.remove() }
    }

    this.rotateImgPressed = function() {
        this.remove()
        self.mouseVec.r = Math.atan2(sketch.mouseY-self.y, sketch.mouseX-self.x)-self.rotation;
        sketch.stopPropagation = true
        self.isRotating = true
    }

    // Change fontStyle
    function getStyle() {
      if (variables.font.style) {
        switch (variables.font.style) {
          case 'italic':
            return sketch.ITALIC;
            break;
          case 'bold':
            return sketch.BOLD;
            break;
          default:
            return sketch.NORMAL;
        }
      } else {
        return sketch.NORMAL;
      }
    }

}
