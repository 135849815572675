function _Symbol(x, y, url, theSketch, decor, variables) {

    var sketch = theSketch
    var self = this //access this from different scope

    this.images = url

    this.updateSketch = function(newSketch) {
      sketch = newSketch
    }

    function loadCorrectImageColor() {
      var urlToReturn = self.images[0].dekoration.url
      self.images.forEach(function(image) {
        if (image.color_id == variables.font.category) {
          urlToReturn = image.dekoration.url
        }
      })
      return urlToReturn
    }


    this.image = sketch.loadImage(loadCorrectImageColor())

    this.w = 80 //width
    this.h = 80 //height
    this.x = x
    this.y = y

    this.mouseVec = {x:0,y:0,r:0}; //Mouse vector

    this.decor = typeof decor == typeof({}) ? decor : {};

    this.rotation = 0
    this.scaling = 0.3
    this.flip = 1

    this.isRotating = false
    this.isDragging = false

    this.removeImg = this.rotateImg = this.rotateImg = this.scaleImg = this.descaleImg = this.flipImg = null

    //todo if one <Symbol> is dragging ignore others display function

    this.reloadImage = function() {
        self.image = sketch.loadImage(loadCorrectImageColor())
    }

    this.display = function (i) {
        sketch.push(); // Start a new drawing state
            sketch.imageMode(sketch.CENTER)
            sketch.stroke(255);
            sketch.fill(255, 255, 255, 0);
            sketch.translate(this.x, this.y)
            sketch.rotate(this.rotation);
            sketch.scale(this.flip*this.scaling, this.scaling)
            sketch.image(this.image, 0, 0, this.image.width, this.image.height); // , this.w, this.h
        sketch.pop(); // Restore original state

        this.w = this.image.width * this.scaling < 100 ? 100 : this.image.width * this.scaling
        this.h = this.image.height * this.scaling < 100 ? 100 : this.image.height * this.scaling

        var m = {x: sketch.mouseX, y: sketch.mouseY}
        var r = getCoords(this.h,this.w,this.x,this.y)
        var inRect = mPointInRectangle(m, r)

        if (this.isDragging) {
            if (true || sketch.mouseX > outerBounds.tlbX && sketch.mouseX < outerBounds.brbX && sketch.mouseY > outerBounds.tlbY && sketch.mouseY < outerBounds.brbY) {
                this.x = sketch.mouseX + this.mouseVec.x
                this.y = sketch.mouseY + this.mouseVec.y
                // this.x = sketch.mouseX
                // this.y = sketch.mouseY
                sketch.cursor(sketch.MOVE)
            }
        } else if (this.isRotating) {
            this.rotation = Math.atan2(sketch.mouseY-this.y, sketch.mouseX-this.x)-this.mouseVec.r; // todo use current this.rotation to prevent initial rotation on click
        }

        this.displayHover(i, inRect)
    }

    this.startDrag = function () {
        var inRect = this.x-this.w/2 < sketch.mouseX && sketch.mouseX < this.x+this.w/2 && this.y-this.h/2 < sketch.mouseY && sketch.mouseY < this.y+this.h/2

        if (inRect) {
            if (true || sketch.mouseX > outerBounds.tlbX && sketch.mouseX < outerBounds.brbX && sketch.mouseY > outerBounds.tlbY && sketch.mouseY < outerBounds.brbY) {
                if (!this.isDragging) {
                  this.mouseVec.x = this.x - sketch.mouseX
                  this.mouseVec.y = this.y - sketch.mouseY
                }

                this.x = sketch.mouseX + this.mouseVec.x
                this.y = sketch.mouseY + this.mouseVec.y

                this.isDragging = true

                return true
            }
        }
        return false
    }

    this.stopDrag = function () {
        this.isDragging = false
        this.isRotating = false
    }

    this.displayHover = function (i, inRect) {
        //if mouse inside display
        this.cleanHover()
        if (inRect && !this.isDragging && !this.isRotating) {

            var imageSize = 20;

            sketch.cursor(sketch.MOVE)
            this.removeImg = sketch.createImg(svgDir + 'close.svg')
            this.removeImg.parent(divSelector)
            this.removeImg.addClass('removeImg')
            this.removeImg.size(imageSize, imageSize)
            // this.removeImg.position(this.x + this.w/2-10, this.y - this.h/2+10)
            this.removeImg.position(this.x + imageSize/2 + 40, this.y + 20)
            this.removeImg.mousePressed(function() {
                sketch.stopPropagation = true
                this.remove()
                self.cleanHover()
                variables.symbols.splice(i, 1)
            })


            this.rotateImg = sketch.createImg(svgDir + 'rotate.svg')
            this.rotateImg.parent(divSelector)
            this.rotateImg.addClass('rotateImg')
            this.rotateImg.size(imageSize, imageSize)
            // this.rotateImg.position(this.x - this.w/2+10, this.y - this.h/2+10)
            this.rotateImg.position(this.x + imageSize/2 + 20, this.y + 20)
            this.rotateImg.mousePressed(this.rotateImgPressed)

            this.flipImg = sketch.createImg(svgDir + 'flip.svg')
            this.flipImg.parent(divSelector)
            this.flipImg.addClass('scale')
            this.flipImg.size(imageSize, imageSize)
            // this.flipImg.position(this.x - this.w/2+10, this.y + this.h/2-10)
            this.flipImg.position(this.x - imageSize/2+20, this.y + 20)
            this.flipImg.mousePressed(this.flipImgPressed)


            this.scaleImg = sketch.createImg(svgDir + 'add.svg')
            this.scaleImg.parent(divSelector)
            this.scaleImg.addClass('scale')
            this.scaleImg.size(imageSize, imageSize)
            //  this.scaleImg.position(this.x + this.w/2-10, this.y + this.h/2-10)
            this.scaleImg.position(this.x - imageSize/2 - 20, this.y + 20)
            this.scaleImg.mousePressed(this.scaleImgPressed)

            this.descaleImg = sketch.createImg(svgDir + 'remove.svg')
            this.descaleImg.parent(divSelector)
            this.descaleImg.addClass('scale')
            this.descaleImg.size(imageSize, imageSize)
            // this.descaleImg.position(this.x - this.w/2+10, this.y + this.h/2-10)
            this.descaleImg.position(this.x - imageSize/2, this.y + 20)
            this.descaleImg.mousePressed(this.descaleImgPressed)
        }
    }

    this.rotateImgPressed = function() {
        this.remove()
        self.mouseVec.r = Math.atan2(sketch.mouseY-self.y, sketch.mouseX-self.x)-self.rotation;
        sketch.stopPropagation = true
        self.isRotating = true
    }

    this.scaleImgPressed = function() {
        this.remove()
        if (dansteinDesigner.defaults.scale.limit && self.scaling < dansteinDesigner.defaults.scale.upper) {
            self.scaling += dansteinDesigner.defaults.scale.stepsize
        }
        sketch.stopPropagation = true
    }

    this.descaleImgPressed = function() {
        this.remove()
        if (dansteinDesigner.defaults.scale.limit && self.scaling > dansteinDesigner.defaults.scale.lower) {
            self.scaling -= dansteinDesigner.defaults.scale.stepsize
        }
        sketch.stopPropagation = true
    }

    this.flipImgPressed = function() {
        this.remove()
        self.flip = self.flip * (-1)
        sketch.stopPropagation = true
    }

    this.cleanHover = function() {
        if (this.removeImg) { this.removeImg.remove() }
        if (this.rotateImg) { this.rotateImg.remove() }
        if (this.scaleImg) { this.scaleImg.remove() }
        if (this.descaleImg) { this.descaleImg.remove() }
        if (this.flipImg) { this.flipImg.remove() }
    }
}
