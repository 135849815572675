app.filter('dansteinDecorations', function() {
    /* --------------------------------------------------
        DECORATIONS:

        We check if what the current stone type is,
        if the text-type is indhugget and
        retrieve the allowed decorations for that
        combination.

    --------------------------------------------------- */
    return function(input,decourCat,colorCat,indhugget) {
        if( typeof(input) != typeof([]) ) { // check if object
            return input;

        } else if( typeof(decourCat) != typeof(0) ) { // check if boolean
            return input;

        } else if( typeof(colorCat) != typeof('0') ) { // check if boolean
            return input;

        } else {
            /*
                insert wierd Danstein decoration logic here:
                check if the decoration type is
            */
            var out = [];


            // Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
            angular.forEach(input, function(decor) {

                var hasCol = false
                // We make sure that the decoration has a version in the current color
                for (let i = 0; i < decor.acf.color_images.length; i++) {
                   if (decor.acf.color_images[i].color_id == colorCat) {
                     hasCol = true
                   }
                }
                if (hasCol) {
                  if (decourCat == decor.decor_cat[0]) {
                    if(!(indhugget && decourCat == 30)) {
                      out.push(decor)
                    }
                  }
                }
            })

            return out;
        }
    }
});

app.filter('dansteinDecorCat', function() {
    /* --------------------------------------------------
        DECORATION CATEGORIES:

        We check if what the text-type is and
        retrieve the allowed categories for that combination.

    --------------------------------------------------- */
    return function(input,indhugget,type) {

        // Check if parameters are of correct type.
        if( typeof(input) != typeof([]) ) {
            return input;
        } else if( typeof(indhugget) != typeof(true) ) {
            return input;
        } else if( typeof(type) != typeof([]) ) {
            return input;
        } else {
            // wierd danstein logic:
            var out = [];

            if(Number.isInteger(type.id) && type.id == 12) {

                angular.forEach(input, function(category) {

                    if(category.id != 30 && category.acf.indhugget == indhugget) {
                        out.push(category)
                    }

                })

            } else {
                angular.forEach(input, function(category) {

                    if(category.id == 30 || category.acf.indhugget == indhugget) {
                        out.push(category)
                    }

                })
            }

            return out;

        }
    }
});

app.filter('dansteinColors', function() {
    /* --------------------------------------------------
        COLORS:

        We check if what the current stone type is,
        if the text-type is indhugget and
        retrieve the allowed colors for that combination.

    --------------------------------------------------- */
    return function(input,indhugget,type) {

        // Check if parameters are of correct type.
        if( typeof(input) != typeof([]) ) {
            return input;
        } else if( typeof(indhugget) != typeof(true) ) {
            return input;
        } else if( typeof(type) != typeof([]) ) {
            return input;
        } else {
            // wierd danstein logic:
            var out = [];

            if(Number.isInteger(type.id) && type.id == 12) {

                angular.forEach(input, function(color) {

                    if(color.farveid != '2' && color.indhugget == indhugget) {
                        out.push(color)
                    }

                })

            } else {
                angular.forEach(input, function(color) {

                    if(color.indhugget == indhugget) {
                        out.push(color)
                    }

                })
            }

            return out;

        }
    }
});
