app.controller('MainCtrl', function ($scope, $location, $state, $rootScope, $anchorScroll, $stateParams, dataFactory, gravDesigner, $sce) {

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options){

        if(toState.name == 3) {
                $scope.fonts = FONTS; // load all fonts.
                setTimeout(function () {
                    var bgData = {};
                    // if using own uploaded image
                    if ($rootScope.customImage) {
                        bgData.bg = $scope.imageUrl;
                    } else {
                        bgData.bg       = ($scope.select.type.id) ? $scope.select.type.acf_bg.url : dansteinDesigner.defaults.images.bg;
                        bgData.stone    = ($scope.select.product.id) ? $scope.select.product.acf.designer_billede.url : dansteinDesigner.defaults.stone.img;
                    }
                    return $scope.changeSketch(bgData);

                }, 500);
                if($scope.select.type.id) {

                    if($scope.select.type.id == 12) {
                        $scope.toggleIndhugget(1);
                    }
                }

        } else if(toState.name == 2) {

            if(fromState.name == 3) {

                var productID       = $scope.select.product.id;
                var navbarHeight    = jQuery('.navbar.navbar-fixed-top').height();
                if(productID) {
                    $location.hash(productID);
                    $anchorScroll.yOffset = navbarHeight;
                    $anchorScroll();
                }

            }
        }

    });
    /* ---------------------------------------------------------------------------------------
        SCOPE
        ------------------------------------
        Variables and function we need to expose to the app frontend.
    */
    $scope.isNumber      = angular.isNumber;
    $scope.loading       = true;
    $scope.colorCategori = '5'; // Sort
    $scope.currentCat    = {id: 47, name: 'Andet'};
    // $scope.indhugget    = true;
    $scope.select       = {
                            type: {},
                            product: {},
                            design: {},
                            image: {},
                            customer: {}
                        };
    $scope.DesignerVariables = {
                    font: {
                        name: dansteinDesigner.defaults.font.family,
                        style: dansteinDesigner.defaults.font.style || 'normal',
                        color: {
                            name: dansteinDesigner.defaults.font.color.name,
                            hex: dansteinDesigner.defaults.font.color.hex,
                            rgb: dansteinDesigner.defaults.font.color.rgb
                        },
                        caps: dansteinDesigner.defaults.font.caps || false,
                        indhugget: dansteinDesigner.defaults.font.indhugget,
                        category: dansteinDesigner.defaults.font.category,
                    },
                    texts: {
                        name: {
                            position: {x: 420, y: 210},
                            content: '',
                            width: 150,
                            fontsize: 54,
                            height: 64,
                            placeholder: '',
                            zindex: 100
                        },
                        date: {
                            position: {x: 420, y: 280},
                            content: '',
                            height: 50,
                            fontsize: 40,
                            width: 150,
                            placeholder: '',
                            zindex: 110,
                            fancy: true
                        },
                        last: {
                            position: {x: 420, y: 340},
                            content: '',
                            width: 150,
                            fontsize: 40,
                            height: 50,
                            placeholder: '',
                            zindex: 120
                        },
                        eks1: {
                            position: {x: 420, y: 400},
                            content: '',
                            width: 150,
                            fontsize: 40,
                            height: 50,
                            placeholder: '',
                            zindex: 120
                        },
                        eks2: {
                            position: {x: 420, y: 460},
                            content: '',
                            width: 150,
                            fontsize: 40,
                            height: 50,
                            placeholder: '',
                            zindex: 120
                        },
                        eks3: {
                            position: {x: 420, y: 520},
                            content: '',
                            width: 150,
                            fontsize: 40,
                            height: 50,
                            placeholder: '',
                            zindex: 120
                        },
                        eks4: {
                            position: {x: 530, y: 400},
                            content: '',
                            width: 150,
                            fontsize: 40,
                            height: 50,
                            placeholder: '',
                            zindex: 120
                        },
                        eks5: {
                            position: {x: 530, y: 460},
                            content: '',
                            width: 150,
                            fontsize: 40,
                            height: 50,
                            placeholder: '',
                            zindex: 120
                        },
                        eks6: {
                            position: {x: 530, y: 520},
                            content: '',
                            width: 150,
                            fontsize: 40,
                            height: 50,
                            placeholder: '',
                            zindex: 120
                        }
                    },
                    symbols: []
    };
    gravDesigner.bindVars($scope.DesignerVariables);

    /*
        Helper function to determine the brightness of the color.
        We calculate luminance
    */

    $scope.isTheColorBlack = function(color) {
        if( typeof(color) == typeof([]) ) {
            var luminance = color.rgb.r * 0.2126 + color.rgb.g * 0.7152 + color.rgb.b * 0.0722;
            if(luminance < 40) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    $scope.toggleIndhugget = function(boolean) {
        if($scope.select.type.id == 12) {
            $scope.DesignerVariables.font.indhugget = true;
        } else {
            if(Number.isInteger(boolean)) {
                if(boolean == 0) {
                    $scope.DesignerVariables.font.indhugget = false;
                } else {
                    $scope.DesignerVariables.font.indhugget = true;
                }
            } else {
                $scope.DesignerVariables.font.indhugget = !$scope.DesignerVariables.font.indhugget;
            }
        }

        // Here we set the first possible color/font/decoration-category
        var firstColorInList = $scope.pagedata.acf.color_list.filter(indFilter)[0]
        $scope.changeFontColor(firstColorInList)
        var firstFontInList = $scope.fonts.filter(indFilter)[0]
        $scope.changeFont(firstFontInList)
        var firstDecorInList = $scope.decorcats.filter(indCatFilter)[0];
        $scope.changeDecorCategory(firstDecorInList);
    }
    var indFilter = function(data) {
        return data.indhugget == $scope.DesignerVariables.font.indhugget;
    }
    var indCatFilter = function(data) {
        return data.acf.indhugget == $scope.DesignerVariables.font.indhugget;
    }

    $scope.setType = function(event) {
      $scope.select.type = event;
      $rootScope.customImage = false
    }
    $scope.setProduct = function(event) {
      $scope.select.product = event
      $rootScope.customImage = false
    }
    $scope.setDesign = function(event) {
      $scope.select.design = event
    }
    $scope.setImageData = function(event) {
      var c = document.getElementById("defaultCanvas0");
      $scope.select.image = c.toDataURL()
    }
    $scope.setCustomer = function(event) {
      $scope.select.customer = event
    }
    $scope.addImage = function() {
      var image = document.getElementById('theImage')
      image.src = $scope.select.image
    }

    /* ---------------------------------------------------------------------------------------
        FONT

        Here we define the GLOBAL font variables, and start off
        by giving them the default values.

        After that we attach functions to the frontend, calling
        the changeFont or changeFontColor depending on which dropdown
        is selected, to update the frontend and sketch.
    */
    $scope.changeFont = function(input) {
        // Update selection object
        $scope.select.design.font       = input;
        // Update current font
        $scope.DesignerVariables.font.name       = input.backup || input.main;
        $scope.DesignerVariables.font.style      = input.fontStyle;
        $scope.DesignerVariables.font.caps       = input.caps || false;
        $scope.DesignerVariables.font.indhugget  = input.indhugget;

        // runs the gravDesigner wrapper-function sketcherFontChange().
        gravDesigner.setFontFunction($scope.DesignerVariables.font);
    }

    $scope.changeFontColor = function(input) {

        // Update selection object
        $scope.select.design.color          = input;
        $scope.select.design.color.bronze   = !input.indhugget;

        // Update Color Category
        $scope.colorCategori                = input.farveid;

        $scope.DesignerVariables.font.color.name             = input.color_name;
        $scope.DesignerVariables.font.color.hex              = input.color;
        $scope.DesignerVariables.font.color.rgb              = hexToRgb(input.color);
        $scope.DesignerVariables.font.category               = input.farveid;

        // runs the gravDesigner wrapper-function sketcherFontChange().
        gravDesigner.setFontFunction($scope.DesignerVariables.font);
    }

    /*---------------------------------------------------------------------------------------
        BACKGROUND

    */
    $scope.changeSketch = function(input) {
        // Update selection object
        $scope.select.design.bg = input.stone;

        // calls gravDesigner wrapper-function sketcherBgChange()
        gravDesigner.setBgFunction(input);
        gravDesigner.bindVars($scope.DesignerVariables);
        gravDesigner.setFontFunction($scope.DesignerVariables.font);
        /*
            Indhugget true or false,
            and sets default font/color
        */
        // $scope.toggleIndhugget()
        $scope.$apply()
    }
    $scope.changeFontSize = function(number, id) {
        if(typeof number === typeof 0) {
            gravDesigner.setFontSize(number, id);
        }
    }
    /*---------------------------------------------------------------------------------------
        TEXT & SYMBOL GENERATION

    */
    $scope.insertSymbol = function(input,decor) {
        // calls gravDesigner wrapper-function insertSymbol()
        gravDesigner.insertSymbol(input,decor);
    }
    $scope.changeDecorCategory = function(input) {
        $scope.currentCat = input;
    }
    $scope.insertText = function(input) {
        // calls gravDesigner wrapper-function insertText()
        gravDesigner.setFontFunction(input)
    }
    $scope.textTest = function() {
        // calls gravDesigner wrapper-function insertText()
        gravDesigner.insertText()
    }

    /* ---------------------------------------------------------------------------------------
        GET CALLS

    */
    $scope.getCptData = function (posttype,page,callback,errorCallback) {
        // console.log(posttype,page);
        dataFactory.fetchData(posttype,100,page)
            .then(function (response) {
                callback(response);
            }, function (error) {
                errorCallback(error);
            });
    }
    $scope.getPageData = function (id,callback,errorCallback) {
        dataFactory.fetchPage(id)
            .then(function (response) {
                callback(response);
            }, function (error) {
                errorCallback(error);
            });
    }
    $scope.getProducts = function (pages, callback,errorCallback) {
        dataFactory.fetchAllProducts(pages, function(response) {
          callback(response);
        })
    }
    $scope.getTypes = function (callback,errorCallback) {
        dataFactory.fetchGraveTypes()
            .then(function (response) {
                callback(response);
            }, function (error) {
                errorCallback(error);
            });
    }
    $scope.getStones = function (callback,errorCallback) {
        dataFactory.fetchStoneTypes()
            .then(function (response) {
                callback(response);
            }, function (error) {
                errorCallback(error);
            });
    }
    $scope.getDecors = function (pages,callback,errorCallback) {

        dataFactory.fetchAllDecors(pages, function(res) {
          callback(res)
        })
    }
    $scope.getDecorCats = function (callback,errorCallback) {
        dataFactory.fetchDecorCats()
            .then(function (response) {
                callback(response);
            }, function (error) {
                errorCallback(error);
            });
    }
    $scope.createPost = function (data, callback,errorCallback) {
        dataFactory.sendPost(data)
            .then(function (response) {
                callback(response);
            }, function (error) {
                errorCallback(error);
            });
    }
    $scope.getPageData(6,function(response){
        $scope.pagedata = response.data;
        $scope.loading = false;
    },function(error){
        console.error(error,'Could not load page data');
        $scope.loading = false;
    });
    $scope.getTypes(function(response){
        $scope.gravetypes = response.data;
        $scope.loading = false;
    },function(error){
        console.error(error,'Could not load gravetypes');
        $scope.loading = false;
    });
    $scope.getProducts(dansteinDesigner.count.product, function(response){
        $scope.products = response.data;
        $scope.loading = false;
    },function(error){
        console.error(error,'Could not load products');
        $scope.loading = false;
    });
    $scope.getStones(function(response){
        $scope.stones = response.data;
        $scope.loading = false;
    },function(error){
        console.error(error,'Could not load stone types');
        $scope.loading = false;
    });
    $scope.getDecors(dansteinDesigner.count.decor,function(response){
        $scope.decors = response.data;
        $scope.loading = false;
    },function(error){
        console.error(error,'Could not load decorations');
        $scope.loading = false;
    });
    $scope.getDecorCats(function(response){
        $scope.decorcats = response.data;
        $scope.loading = false;
    },function(error){
        console.error(error,'Could not load decoration categories');
        $scope.loading = false;
    });

    $scope.fileChange = function(image) {
        $scope.imageUrl = URL.createObjectURL(image)
        $rootScope.customImage = true
        $state.go('3')
    }

    // POST
    $scope.sendPost = function(postobject) {
        /* ----------------------------------------
            Here we create an object with the data
            we would like to send to WordPress.

        */

        $('#submit-knap').attr("disabled", true);

        var henvendelse = {
            'action' : 'danstein_save_meta_data',
            'imageMeta': postobject.image,
            'navn' : postobject.customer.name,
            'email': postobject.customer.email,
            'telf': postobject.customer.telefon,
            'kirke': postobject.customer.kirkekard,
            'comments': postobject.customer.comments,
            'metaData': JSON.stringify($scope.DesignerVariables)
        };

        dataFactory.sendPost(henvendelse)
        .then(function (response) {
            console.log(response)
        }, function (error) {
            console.error(error)
        });

        // Here we set the AJAX settings.
        var objektet = {
            url: dansteinDesigner.wordpress.ajaxurl,
            method: 'POST',
            data : henvendelse,
            success:function(metadata) {
                console.log('success');
                console.log(metadata)
                $('#successModal').modal('toggle');
                // $('#submit-knap').attr("disabled", true);
            },
            error:function(err) {
                console.error(err);
                // $('#submit-knap').attr("disabled", false);
            }
        }
        return $.ajax(objektet);
    };


})
