// "use strict";

// angular_vars.js MUST be loaded first.
// jQuery must be loaded first.

var app = angular.module('danstein', [
        'ui.router',
        'ngSanitize',
        'angular-p5',
        'angulartics',
        'angulartics.google.tagmanager'
    ]);

app.config(function($httpProvider, $stateProvider, $urlRouterProvider) {

    // Security measure CSRF - Nonce
    $httpProvider.interceptors.push([function() {
        return {
            'request': function(config) {
                config.headers = config.headers || {};
                //add nonce to avoid CSRF issues
                config.headers['X-WP-Nonce'] = dansteinDesigner.wordpress.nonce;

                return config;
            }
        };
    }]);

    // Wordpress theme path
    var WordpressThemeURL = dansteinDesigner.wordpress.themedir

    // For any unmatched url, redirect to /
    $urlRouterProvider.otherwise('1');

    $stateProvider

        // HOME STATES AND NESTED VIEWS ========================================
        .state('1', {
            url: '/1',
            templateUrl: WordpressThemeURL + 'parts/step1.html'
        })
        .state('2', {
            url: '/2',
            templateUrl: WordpressThemeURL + 'parts/step2.html',
        })
        .state('3', {
            url: '/3',
            templateUrl: WordpressThemeURL + 'parts/step3.html',
        })
        .state('4', {
            url: '/4',
            templateUrl: WordpressThemeURL + 'parts/step4.html'
        })
        .state('upload', {
            url: '/upload',
            templateUrl: WordpressThemeURL + 'parts/upload.html'
        });

});

/*
    To improve user experience, we should integrate a message service,
    that also redirects the user if they are on the wrong page.


app.run( function($rootScope, $location) {

    // register listener to watch route changes
    $rootScope.$on( '$stateChangeStart', function(event, next, current) {

        if(next.url == '/3'){

            alert('Du har ikke valgt nogen sten')
            $location.path( '1' );

        }

    });

});
*/
